import React from 'react';
import Papa from 'papaparse';
import './CSVUpload.css';

const CSVUpload = ({ onCSVSubmit,apiKey }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
           // Send the parsed data up to the parent component
          onCSVSubmit(results.data); 
          
        }
      });
    }
  };

  return (
    <div className="csv-upload-container">
    <label htmlFor="file-upload" className="custom-file-upload">
      Select CSV File
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="custom-file-input"
        id="file-upload"
      />
    </label>
  </div>
  );
};

export default CSVUpload;
