import React from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import logo from '../images/GCA-Domain-Trust-Logo.png';
import './Sidebar.css';
function Sidebar({apiKey}) {
    let history = useHistory();
    const logout = async (e) => {
        
        e.preventDefault();
        localStorage.setItem('token','')
        history.push('/login');
        
    }
    return (
        <div className="sidebar">
            <img src={logo} alt="Domain Trust Logo" className="dt-logo" />
            
            <NavLink exact to="/dashboard" activeClassName="active">Dashboard</NavLink>
            <NavLink to="/submit-domain" activeClassName="active">Submit Domain</NavLink>
            <NavLink to="/lookup" activeClassName="active">Lookup Domain</NavLink>
            <NavLink to="/taxonomy" activeClassName="active">Taxonomy</NavLink>
       
            <button onClick={logout}>Logout</button>
           
          
        </div>
    );
}
export default Sidebar; 