import React from 'react';
import Sidebar from './Sidebar';
import './Dashboard.css';

function Dashboard({apiKey}) {
    return (
        <div className="dashboard" >
            <div className="sidebar-container">
            <Sidebar />

            </div>
            
            <div className="content fade-in">
                <h2>Domain Trust Platform</h2>
                <p >Building Trust in the Domain Name Ecosystem</p>
                <h5>Alternate Web-Based Domain Submission and Data Sharing Tool</h5>
                <div className="content-container">
                <div class="left">
                     <img src="/static/assets/images/GCA-Tools-Domain-Trust-Laptop.png" alt="Domain Trust Laptop" />
                </div>
                <div className="right">
                  
                    <h5>Improving Data Collection and Submissions</h5>
                    <p>Domain Trust partners represent multiple sectors and have varied technological capabilities and preferences; thus, it is important to support our partners in their efforts to share information with each other about malicious domains.</p>
    
                    <h5>Web-Based Support</h5>
                    <p>This web-based user interface tool simplifies the process of submitting suspected or known malicious domains into the Domain Trust Platform, allowing single and multiple domain submissions through the online form, individually or by uploading .csv files.</p>
                       
                    <h5> Application Programming Interface (API) Support</h5>
                    <p>Partners may still choose to use our API process to submit domains directly into the platform, if they prefer. Both processes are fully integrated within the Domain Trust Platform, ensuring continuity and alternative options for all of our partners. For assistance using our API, please see Domain Trust <a className='links' href="https://domain-trust.globalcyberalliance.org/docs">API</a> or contact <a className='links' href="mailto:domaintrust@globalcyberalliance.org">domaintrust@globalcyberalliance.org</a>.</p>
          
                </div>
                </div>
            </div>       
        </div>
    );
}
export default Dashboard;
