import React from 'react';
import Sidebar from './Sidebar';
import './TaxonomyDetails.css'; // Import your CSS file for styling

const TaxonomyDetails = () => {
  return (
    <div className="taxonomy-details">
        <Sidebar />
        <div className="content">
        <h2>Domain Trust Platform</h2>
                <p >Building Trust in the Domain Name Ecosystem</p>
      <h3>Taxonomy Details</h3>
     

<table className="taxonomy-table">
    <thead>
        <tr>
            <th>Field Title</th>
            <th>Field Value</th>
            <th>Field Value Description</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Provider</td>
            <td></td>
            <td>Name of provider</td>
        </tr>
        <tr>
            <td rowspan="4">Provider rating</td>
            <td>A</td>
            <td>High authority provider such as police officer or judge.</td>
        </tr>
        <tr>
            <td>B</td>
            <td>Medium authority provider with high scale SOC such as CERTs/CSIRTs, ISPs and TELCOs.</td>
        </tr>
        <tr>
            <td>C</td>
            <td>Low authority provider such as anti-spam and anti-scam contributors.</td>
        </tr>
        <tr>
            <td>D</td>
            <td>Predictive intelligence provider such as artificial intelligence contributors.</td>
        </tr>
        <tr>
            <td rowspan="4">Classification</td>
            <td>1</td>
            <td>Definitely criminal (in the providers opinion)</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Probably criminal (in the providers opinion)</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Possible criminal</td>
        </tr>
        <tr>
            <td>-1</td>
            <td>Definitely not criminal, designed to allow loading of allowed lists, negative false positives or investigations underway</td>
        </tr>
        <tr>
            <td rowspan="2">Source Flag</td>
            <td>S</td>
            <td>Self</td>
        </tr>
        <tr>
            <td>E</td>
            <td>External</td>
        </tr>
        <tr>
            <td>Source Name</td>
            <td></td>
            <td>Name of source</td>
        </tr>
        <tr>
            <td rowspan="6">Activity</td>
            <td colspan="2">Used by registries/registrars, or ICANN, to flag that a domain is</td>
        </tr>
        <tr>
            <td>A</td>
            <td>Active</td>
        </tr>
        <tr>
            <td>S</td>
            <td>Suspended</td>
        </tr>
        <tr>
            <td>N</td>
            <td>Non-existent</td>
        </tr>
        <tr>
            <td>T</td>
            <td>Taken Down</td>
        </tr>
        <tr>
            <td>B</td>
            <td>Blocked</td>
        </tr>
        <tr>
            <td>Event Date</td>
            <td></td>
            <td>Recorded event date </td>
        </tr>
        <tr>
            <td>Submission Date</td>
            <td></td>
            <td>Date submitted to the platform</td>
        </tr>
        <tr>
            <td rowspan="3">Type (optional)</td>
            <td></td>
            <td>Cyber</td>
        </tr>
        <tr>
            <td>F</td>
            <td>Fraud</td>
        </tr>
        <tr>
            <td>B</td>
            <td>Brand spoof</td>
        </tr>
        <tr>
            <td>Role of provider</td>
            <td></td>
            <td>Registry, Registrar, Reseller, ICANN, Other</td>
        </tr>
        <tr>
            <td>Creation date</td>
            <td></td>
            <td>Date domain was created - to be used by Registry/Registrar</td>
        </tr>
    </tbody>
</table>
      </div>
    </div>
  );
};

export default TaxonomyDetails;
