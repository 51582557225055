import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import DomainSubmitForm from './components/DomainSubmitForm';
import LookupForm from './components/LookupForm';
import LoginForm from './components/LoginForm';
import TaxonomyDetails from './components/TaxonomyDetails';
import NotFound from './components/NotFound';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [apiKey, setApiKey] = useState('');
    // You can also store email if you need it for later
    const [email, setEmail] = useState('');

    // A simple way to "authenticate". This should be replaced with your actual auth logic.
    const handleLogin = (email, apiKey) => {
        // Validate credentials and set isLoggedIn
        setIsLoggedIn(true);
        setEmail(email);
        setApiKey(apiKey);
        // Here, implement actual authentication logic
    };
    // A Protected Route component
    const ProtectedRoute = ({ component: Component,apiKey, ...rest }) => (
        <Route {...rest} render={(props) => (
            isLoggedIn ? <Component {...props} apiKey={apiKey} /> : <Redirect to="/login" />
        )} />
    );
    return (
        <Router>
            <Switch>
            <Route exact path="/login" render={(props) =>  <LoginForm onLogin={handleLogin} apiKey={apiKey} {...props} />} />
                <ProtectedRoute path="/dashboard" component={Dashboard} apiKey={apiKey} />
                <ProtectedRoute path="/lookup" component={LookupForm} apiKey={apiKey} />
                <ProtectedRoute path="/submit-domain" component={DomainSubmitForm} apiKey={apiKey} />
                <ProtectedRoute path="/taxonomy" component={TaxonomyDetails} />
                <Redirect from="" to="/login" />
                <Route path="*" component={NotFound} />
            </Switch>
        </Router>
    );
}
export default App; 