import React, { useState, useMemo} from 'react';
import Sidebar from './Sidebar';
import config from '../config';
import './LookupForm.css';
function LookupForm({apiKey}) {
    const [domain, setDomain] = useState('');
    const [domainInfo, setDomainInfo] = useState(null);
    const [domainHistory, setDomainHistory] = useState(null); 
    const [loading, setLoading] = useState(false); 
    const [error, setError] = useState(''); 
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 20;

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(''); // Clear errors
        setDomainInfo(null)
        setDomainHistory(null)
    
        //  API call
        try {
            const url = `${config.BASE_URL}/domain/`
            const response = await fetch(url+domain, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiKey
                },
               
            });
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('Domain Not Found');
                } else {
                    throw new Error('Invalid API Key');
                }
            }
            const data = await response.json();
            setDomainInfo(data.domain);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };




    const handleHistoryLookUp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(''); // Clear errors
        setDomainHistory(null)
  
        
        //  API call
        try {
            const url = `${config.BASE_URL}/domain/history?domain=`
            const response = await fetch(url+domain+"&limit=10000", {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiKey
                },
                
            });
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('Domain Not Found');
                } else {
                    throw new Error('Invalid API Key');
                }
            }
            const data = await response.json();
         
            setDomainHistory(data.domains);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const sortedItems = useMemo(() => {
        if (!domainHistory) return []; 
        let sortableItems = [...domainHistory]; 
        if (sortConfig !== null) {
          sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
        }
        return sortableItems;
      }, [domainHistory, sortConfig]);


      const indexOfLastEntry = currentPage * entriesPerPage;
      const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
      const currentEntries = sortedItems.slice(indexOfFirstEntry, indexOfLastEntry);
    
      const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(sortedItems.length / entriesPerPage); i++) {
          pageNumbers.push(i);
        }
      
        const totalPages = pageNumbers.length;
        const maxVisiblePages = 4;
      
        const getPageBlock = () => {
          const pageBlock = [];
          const startPage = Math.max(1, Math.min(currentPage - Math.floor(maxVisiblePages / 2), totalPages - maxVisiblePages + 1));
          const endPage = Math.min(startPage + maxVisiblePages, totalPages + 1);
      
          for (let i = startPage; i < endPage; i++) {
            pageBlock.push(
              <span
                key={i}
                className={i === currentPage ? 'active' : ''}
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </span>
            );
          }
      
          return pageBlock;
        };
      
        return (
          <div className="pagination">
            <span onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>&laquo;</span>
            {getPageBlock()}
            <span
              onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}
            >
              &raquo;
            </span>
          </div>
        );
      };
      
      


      const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
      };

      const getSortDirectionIndicator = (columnName) => {
        if (sortConfig.key === columnName) {
          return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '▼'; 
      };


      const formatTimestamp = (timestamp) => {
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        };
      
        return new Date(timestamp).toLocaleString('en-US', options);
      };

    return (
        <div className="lookup-page">
            <Sidebar />
            <div className="content">
                <div className="title-container">
                <h2>Domain Trust Platform</h2>
                <p >Building Trust in the Domain Name Ecosystem</p>
                    <h3>Domain Information Lookup</h3>
                </div>
                <div className="form-container">
                    <form onSubmit={handleSubmit} className="lookup-form">
                        <input
                            type="text"
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            placeholder="Exact Domain to lookup"
                            required
                        />
                        <button type="submit" disabled={loading}>
                            {loading ? 'Looking up...' : 'Lookup Domain'}
                        </button>
                    </form>
                </div>
                {error && <div className="lookup-error">{error}</div>}
                {domainInfo && (
                    <div className="card fade-in">
                        <h2>Domain Information</h2>
                        <ul>
                        <li><strong>Domain:</strong> {domainInfo.domain}</li>
                       
                        <li><strong>Is Blocked:</strong> {domainInfo.is_blocked ? 'Yes' : 'No'}</li>
                        <li><strong>Provider:</strong> {domainInfo.provider}</li>
                        <li><strong>Rating:</strong> {domainInfo.rating}</li>
                        <li><strong>Classification:</strong> {domainInfo.classification}</li>
                        <li><strong>Source:</strong> {domainInfo.source}</li>
                        <li><strong>Source Name:</strong> {domainInfo.source_name}</li>
                        <li><strong>Tld:</strong> {domainInfo.tld}</li>
                        <li><strong>Activity:</strong> {domainInfo.activity}</li>
                        <li><strong>Type:</strong> {domainInfo.type}</li>
                        <li><strong>Event Date:</strong> {formatTimestamp(domainInfo.date)}</li>
<li><strong>Submission Date:</strong> {formatTimestamp(domainInfo.timestamp)}</li>
<div className='view-history'>
                            <form onSubmit={handleHistoryLookUp}>

                        <button type='submit'>
                        View Submission History

                    </button>
                    </form>
                    </div>
               
                        
                       </ul>
                        
                    </div>
                   
                    
                ) }

{domainHistory && (
                    <div className='domain-history fade-in'>
                     
                        <h3>Submission History</h3>
                        <p> <strong>{domainInfo.domain} </strong> has been submitted a total of {domainHistory.length} {domainHistory.length === 1 ? 'time' : 'times'}</p>
                
                        <table>
                            <thead>
                                <tr>
                               
           <th onClick={() => requestSort('provider')}>
            Provider{getSortDirectionIndicator('provider')}
          </th>
                                <th onClick={() => requestSort('is_blocked')}>
            Is Blocked{getSortDirectionIndicator('is_blocked')}
          </th>                      
                                   
                                    <th onClick={() => requestSort('rating')}>
            Rating{getSortDirectionIndicator('rating')}
          </th>
                                    <th onClick={() => requestSort('classification')}>
            Classification{getSortDirectionIndicator('classification')}
          </th>
                                    <th onClick={() => requestSort('activity')}>
            Activity{getSortDirectionIndicator('activity')}
          </th>
                                    <th onClick={() => requestSort('type')}>
            Type{getSortDirectionIndicator('type')}
          </th>
                                    <th onClick={() => requestSort('date')}>
            Event Date{getSortDirectionIndicator('date')}
          </th>
          <th onClick={() => requestSort('timestamp')}>
            Submission Date{getSortDirectionIndicator('timestamp')}
          </th>
          <th onClick={() => requestSort('tld')}>
            TLD{getSortDirectionIndicator('tld')}
          </th>
         
                             

               
                                </tr>
                            </thead>
                            <tbody>
                              
                                {currentEntries.map((historyEntry) => (
                                    <tr key={historyEntry.id}>
                                       
                                       
                                        <td>{historyEntry.provider}</td>
                                        
                                        <td>{historyEntry.is_blocked ? 'Yes' : 'No'}</td>
                                        <td>{historyEntry.rating}</td>
                                        <td>{historyEntry.classification}</td>
                                        <td>{historyEntry.activity}</td>
                                        <td>{historyEntry.type}</td>
                                       
                                       
                                        <td>{formatTimestamp(historyEntry.date)}</td>
                                     
                                        <td>{formatTimestamp(historyEntry.timestamp)}</td>
                                        <td>{historyEntry.tld}</td>
            
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
              <span onClick={() => setCurrentPage(1)}>&laquo;</span>
              {renderPageNumbers()}
              <span onClick={() => setCurrentPage(Math.ceil(sortedItems.length / entriesPerPage))}>
                &raquo;
              </span>
            </div>


                    </div>
                )}

                
              
            </div>

        </div>
    );
}
export default LookupForm;