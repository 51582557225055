import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import CSVUpload from './CSVUpload';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import exampleCSV from '../example/example.csv';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
import './DomainSubmitForm.css';
function DomainSubmitForm({apiKey}) {
    const DNSNamePattern = "^(?:[_a-z0-9](?:[_a-z0-9-]{0,61}[a-z0-9])?\\.)+(?:[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?)?$";
    const [domainData, setDomainData] = useState({
        activity: '',
        classification: '',
        date: '',
        domain: '',
        registrationDate: '',
        source: '',
        sourceName: '',
        type: '',
    });

    const [allDomains, setAllDomains] = useState([]); // State for all domain entries
    const [loading, setLoading] = useState(false); // State for loading state
    const [error, setError] = useState(''); // State for error messages
    const [status, setStatus] = useState(''); // State for status messages
    const [activeTab, setActiveTab] = useState('submitDomain');

    const [currentPage, setCurrentPage] = useState(1);
    const [domainsPerPage, setDomainsPerPage] = useState(20);
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [allDomains, currentPage]);
  
    const totalPages = Math.ceil(allDomains.length / domainsPerPage);
  
    const getPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 4;
  
      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        const leftOffset = Math.min(Math.max(currentPage - 2, 1), totalPages - maxPagesToShow + 1);
        for (let i = leftOffset; i < leftOffset + maxPagesToShow; i++) {
          pageNumbers.push(i);
        }
      }
  
      return pageNumbers;
    };
  
    const currentDomains = allDomains.slice((currentPage - 1) * domainsPerPage, currentPage * domainsPerPage);
  
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
    const goToPrevSet = () => setCurrentPage(Math.max(currentPage - 4, 1));
  
    const goToNextSet = () => setCurrentPage(Math.min(currentPage + 4, totalPages));
  

    const handleInputChange = (e) => {
     
        const { name, value } = e.target;
    if (name === "domain") {
        setDomainData({ ...domainData, [name]: value.toLowerCase() });
    } else {
        setDomainData({ ...domainData, [name]: value });
    }
    };

    const getUnixTime = (dateString) => {
        // Check if dateString is already a Unix timestamp (number only)
        if (/^\d+$/.test(dateString)) {
          return parseInt(dateString, 10); // Return as a number
        }
      
        // Otherwise, assume dateString is in MM/DD/YYYY format and convert to Unix timestamp
        const dateParts = dateString.split('/');
        const dateObject = new Date(+dateParts[2], dateParts[0] - 1, +dateParts[1]);
        return Math.floor(dateObject.getTime() / 1000);
      };

      const isValidDomain = (domainString) => {
        const DNSNameRegex = /^(?:[_a-z0-9](?:[_a-z0-9-]{0,61}[a-z0-9])?\.)+(?:[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?)?$/;
        domainString = domainString.replace(/^www\./, ''); // Remove 'www.' prefix
        if (domainString === "" || domainString.replace(/\./g, '').length > 255) {
            return false; // Violates length constraints
        }
        if (domainString.startsWith('www.')) {
            return false; // Starts with 'www.'
        }
        return DNSNameRegex.test(domainString); // Validate against regex
    };
      
    const handleAddDomain = (e) => {
        e.preventDefault();
        setStatus('');
        setError('');
      
       
        // Validation logic
        if (!domainData.domain || !domainData.activity || !domainData.classification ||
            !domainData.date || !domainData.source || !domainData.sourceName) {
            alert('Please fill out all required fields.');
            return;
        }
        if (!isValidDomain(domainData.domain)) {
           
            setError('Invalid domain format');
            return;
        }
        // Construct the domain entry
        const domainEntry = {
            ...domainData,
            date: getUnixTime(domainData.date),
            registrationDate: getUnixTime(domainData.registrationDate),
        };
        // Add the new domain entry to the list of all domains
        setAllDomains([...allDomains, domainEntry]);
        // Clear the form data
        setDomainData({
            activity: '',
            classification: '',
            date: '',
            domain: '',
            registrationDate: '',
            source: '',
            sourceName: '',
            type: '',
        });
    };

    
    const handleSubmitAllDomains = async () => {
        
        setError(''); // Clear any previous errors
        setStatus('')
        let errorString=''
        for (const domain of allDomains) {
            if (!isValidDomain(domain.domain)) {
                errorString= errorString +'   '+ domain.domain;
                setError(`Invalid domain format: ${errorString}`);
                
            }
            if (error){
                return;

            }
        }
        
        try {
            const url = `${config.BASE_URL}/domain/`
            const response = await fetch(url, {
                method: 'POST', // or 'GET' depending on your API
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiKey
                },
                 body: JSON.stringify(allDomains, null, 2),
            });
            if (!response.ok) {
                setError(response.status);
                throw new Error(`HTTP error! status: ${response.status}`);
                
            }
            const data = await response.json();
          
            setStatus('Domains Submitted successfully')
            
        } catch (error) {

            console.error('Error during domain submission:', error);
            setError(error.message); 
           
        } finally {
            setLoading(false);
        }
        setAllDomains([]);
        
    };



    const validateCSVRow = (row) => {
        setError('');
        setStatus('');
        // Patterns for validation
        const DNSNamePattern = new RegExp("^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.)+(?:[a-zA-Z](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)?$");
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        const unixTimestampPattern = /^\d+$/;
        // Check domain
        if (!DNSNamePattern.test(row.domain.toLowerCase())) {
            return 'Invalid domain format';
        }
            // Check date for MM/DD/YYYY format or Unix timestamp
    if (row.date && !datePattern.test(row.date) && !unixTimestampPattern.test(row.date)) {
        return 'Invalid date format (expected MM/DD/YYYY or Unix timestamp)';
    }
    // Check registration date for MM/DD/YYYY format or Unix timestamp
    if (row.registrationDate && !datePattern.test(row.registrationDate) && !unixTimestampPattern.test(row.registrationDate)) {
        return 'Invalid registration date format (expected MM/DD/YYYY or Unix timestamp)';
    } 
       
        return '';
    }; 

    const handleCSVSubmit = (parsedData) => {
        setError('');
        setStatus('');
       
        if (!parsedData.length) {
            setError('No data found in the CSV file.');
            return;
        }
        const validatedData = [];
        for (const row of parsedData) {
            const errorMessage = validateCSVRow(row);
            if (errorMessage) {
                setError(`${row.domain} : Error in row ${parsedData.indexOf(row) + 1}: ${errorMessage}`);
                return;
            }
            row.domain = row.domain.toLowerCase()
            if(row.date){
                row.date = getUnixTime(row.date)
            }else {
                row.date = ''
            }
            if(row.registrationDate){
                row.registrationDate =  getUnixTime(row.registrationDate) 
            }
            validatedData.push(row);
        }
       
      
        setAllDomains([...allDomains, ...validatedData]);
    };

    const handleDeleteDomain = (index) => {
           
        const updatedDomains = [...allDomains];

        updatedDomains.splice(index, 1);

        setAllDomains(updatedDomains);

    } 

      
      
    return (
<div className="domain-submit">
<Sidebar />
<div className="content fade-in">
<h2>Domain Trust Platform</h2>
                <p >Building Trust in the Domain Name Ecosystem</p>
  <div className="tabs">
    <button
      onClick={() => setActiveTab('uploadCsv')}
      className={activeTab === 'uploadCsv' ? 'active' : ''}
    >
      CSV
    </button>
    <button
      onClick={() => setActiveTab('submitDomain')}
      className={activeTab === 'submitDomain' ? 'active' : ''}
    >
      Web Form
    </button>
  </div>
  {activeTab === 'uploadCsv' && (
    <div className="csv-upload-container">
     <div className="content">
            <div className="csv-instructions">
    <h3>CSV Format Instructions:</h3>
    <ul>
        <li>Maximum allowed entries 18,000</li>
        <NavLink to="/taxonomy" activeClassName="active">Taxonomy</NavLink>
        <li>Please ensure your CSV file has the following columns in this order:</li>
        <li>Domain (e.g., example.com)</li>
        <li>Activity (A, S, N, T, B)</li>
        <li>Classification (1, 2, 3, -1)</li>
        <li>Event Date (MM/DD/YYYY or Unix Timestamp)</li>
        <li>Registration Date (MM/DD/YYYY or Unix Timestamp or leave blank)</li>
        <li>Source (S, E)</li>
        <li>Source Name</li>
        <li>Type (F, B or leave blank)</li>
        <li><a href={exampleCSV} download="example.csv">Download Example CSV</a></li>
       
    </ul>
</div>
    
      <CSVUpload onCSVSubmit={handleCSVSubmit} />
   
    </div>

    </div>
  )}
  {activeTab === 'submitDomain' && (
    <div className="submit-domain-container">
       
                <div className="form-container">
                    <form onSubmit={handleAddDomain} className="domain-form">
                        <input
                             type="text"
                             name="domain"
                             value={domainData.domain}
                             onChange={handleInputChange}
                             placeholder="Domain"
                             pattern={DNSNamePattern}
                             title="Please enter a valid domain name."
                             
                             required
                         />
                         <select
                             name="activity"
                             value={domainData.activity}
                             onChange={handleInputChange}
                             required
                         >
                             <option value="">Select Activity</option>
                             <option value="A">A</option>
                             <option value="S">S</option>
                             <option value="N">N</option>
                             <option value="T">T</option>
                             <option value="B">B</option>
                         </select>
                         <select
                             name="classification"
                             value={domainData.classification}
                             onChange={handleInputChange}
                             required
                         >
                             <option value="">Select Classification</option>
                             <option value="1">1</option>
                             <option value="2">2</option>
                             <option value="3">3</option>
                             <option value="-1">-1</option>
                         </select>
                         <input
                             type="text"
                             name="date"
                             value={domainData.date}
                             onChange={handleInputChange}
                             placeholder="Event Date (MM/DD/YYYY)"
                             pattern="\d{2}/\d{2}/\d{4}"
                             required
                         />
                         <input
                             type="text"
                             name="registrationDate"
                             value={domainData.registrationDate}
                             onChange={handleInputChange}
                             placeholder="Registration Date (MM/DD/YYYY)"
                             pattern="\d{2}/\d{2}/\d{4}"
                            
                         />
                         <select
                             name="source"
                             value={domainData.source}
                             onChange={handleInputChange}
                             required
                         >
                             <option value="">Select Source</option>
                             <option value="S">S</option>
                             <option value="E">E</option>
                         </select>
                         <input
                             type="text"
                             name="sourceName"
                             value={domainData.sourceName}
                             onChange={handleInputChange}
                             placeholder="Source Name"
                             required
                         />
                         <select
                             name="type"
                             value={domainData.type}
                            onChange={handleInputChange}
                           
                        >
                             <option value="">Select Type</option>
                             <option value="F">F</option>
                             <option value="B">B</option>
                           
                         </select>    
                        <button type="submit">Add Domain</button>
                    </form>
                </div>
            
    </div>
  )}


<div className="domain-list-container">
      {error && <div className="domain-submit-error">{error}</div>}
      {status && <div className="domain-submit-status">{status}</div>}
      {allDomains.length > 0 && (
        <>
          <button
            type="submit"
            onClick={handleSubmitAllDomains}
            className="submit-all-btn"
          >
            Submit All Domains
          </button>
       
   <div className="domain-list">
          <h2>Domains to be submitted:</h2>
                {/* Pagination */}
        {totalPages > 1 && (
          <div className="pagination">
            <button onClick={goToPrevSet}>&laquo;</button>
            {getPageNumbers().map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={currentPage === number ? 'active' : ''}
              >
                {number}
              </button>
            ))}
            <button onClick={goToNextSet}>&raquo;</button>
          </div>
        )}

          <ul>
            {currentDomains.map((domain, index) => (
              <li key={index}>
                <span>{`${domain.domain} - ${domain.activity}`}</span>
                <button
                  type="button"
                  onClick={() => handleDeleteDomain((currentPage - 1) * domainsPerPage + index)}
                  className="delete-btn"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </li>
            ))}
          </ul>
        </div>

  



        </>
      )}
    </div>
</div>
</div>
        
    );
}
export default DomainSubmitForm;