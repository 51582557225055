import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../config';
import './LoginForm.css';

import logo from '../images/GCA-Domain-Trust-Logo.png';
function LoginForm({ onLogin }) {
   
    const [email, setEmail] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(''); 
    let history = useHistory();
    useEffect(() => {
        const apiKeyFromStorage = localStorage.getItem('token');
        if(apiKeyFromStorage){
            
            onLogin('', apiKey);         
            history.push('/dashboard');
        }
     });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); 
        setError(''); // Clear errors
        try{
            const url = `${config.BASE_URL}/profile`
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiKey,
                },
            });
            if (!response.ok) {
                throw new Error('Invalid API Key');
            }
            //  handle the login
            const data = await response.json(); 
            localStorage.setItem('token', apiKey);
            onLogin(email, apiKey);
           
            history.push('/dashboard');
        } catch (error) {
            console.error('Error during login:', error);
            setError(error.message); 
        } finally {
            setLoading(false); 
        }
    };
    return (
        <div className="login-container">
            <div className="content">
               <h2>Domain Trust</h2>
                <p >Building Trust in the Domain Name Ecosystem</p>
             
            <div className="login-form">
                <img src={logo} alt="Domain Trust Logo" className="login-logo" />
                <h4>Platform Login</h4>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="apiKey">Login/API Key</label>
                        <input
                            type="password"
                            id="apiKey"
                            value={apiKey}
                            onChange={(e) => setApiKey(e.target.value)}
                            placeholder="Enter your API Key"
                            required
                        />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                    {error && <div className="login-error">{error}</div>}
                </form>
            </div>
            </div>
        </div>
    );
}
export default LoginForm;